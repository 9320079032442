import React, { useState } from 'react'
import { Redirect } from 'react-router'
import styled from 'styled-components'
import { useAsync } from 'react-async'
import Button from '../components/Button'
import Card from '../components/Card'
import Heading from '../components/Heading'
import Column from '../components/Column'
import Link from '../components/Link'
import Logo from '../components/Logo'
import Text from '../components/Text'
import TextInput from '../components/TextInput'
import { signup } from '../lib/api'
import { mediaQueries, spacing } from '../tokens'

import {
  strengthColor,
  strengthIndicator,
  strengthMessage,
} from '../util/password'
import { useUser } from '../context/userContext'

interface Props {
  [propName: string]: any
}

interface User {
  name: string
  email: string
  password: string
  confirmPassword: string
}

function SignupComponent({ location }: Props) {
  const { user, reloadUser } = useUser()

  const [credentials, setCredentials] = useState<User>({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  })
  const [passwordMatch, setPasswordMatch] = useState<boolean>(true)
  const [strengthPassword, setStrengthPassword] = useState<any>(null)

  const { error, isLoading, run } = useAsync({
    deferFn: args => signup(args[0], args[1]),
    onResolve: () => reloadUser(),
  })

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!passwordMatch) {
      return
    }
    run(credentials)
  }

  function handleChange(value: string, id: string) {
    setCredentials({
      ...credentials,
      [id]: value,
    })

    if (id === 'password') {
      setPasswordMatch(value === credentials.confirmPassword)
      setStrengthPassword(strengthIndicator(value))
    } else if (id === 'confirmPassword') {
      setPasswordMatch(value === credentials.password)
    }
  }

  function getURLBeforeSingIn() {
    let URLTo = '/'
    if (location.state && location.state.from.pathname) {
      URLTo = location.state.from.pathname
    }
    return URLTo
  }

  if (user) {
    const URLTo = getURLBeforeSingIn()
    return <Redirect to={URLTo} />
  }

  return (
    <Column background>
      <Logo />
      <LoginWrapper>
        <Card alignCenter>
          <Card.Body paddingSize="large">
            <Heading>Crea una cuenta</Heading>
            <Text>¿Ya tienes una cuenta? </Text>
            <Link to={'/iniciar-sesion'}>Inicia sesión</Link>
            <WrapperForm>
              <form onSubmit={handleSubmit}>
                <TextInput
                  label="Nombre Completo"
                  name="name"
                  onChange={handleChange}
                  required
                  value={credentials.name}
                />
                <TextInput
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  required
                  type="email"
                  value={credentials.email}
                />
                <TextInput
                  label="Contraseña"
                  name="password"
                  onChange={handleChange}
                  required
                  type="password"
                  value={credentials.password}
                />
                {strengthPassword !== null && credentials.password !== '' && (
                  <MessageContainer>
                    <Text status={strengthColor(strengthPassword)}>
                      {strengthMessage(strengthPassword) || ''}
                    </Text>
                  </MessageContainer>
                )}
                <TextInput
                  label="Confirmar Contraseña"
                  name="confirmPassword"
                  onChange={handleChange}
                  required
                  type="password"
                  value={credentials.confirmPassword}
                />
                {!passwordMatch && credentials.confirmPassword && (
                  <MessageContainer>
                    <Text status="danger">Las contraseñas no coinciden</Text>
                  </MessageContainer>
                )}
                {error && (
                  <MessageContainer>
                    <Text status="danger">{error.message}</Text>
                  </MessageContainer>
                )}
                <Button disabled={isLoading || !passwordMatch} type="submit">
                  Crear Cuenta
                </Button>
              </form>
            </WrapperForm>
            <p>
              <Text>Al registrarte confirmas que aceptas los </Text>
              <Link href="https://alau.org/terminos-y-condiciones">
                términos y condiciones
              </Link>
              <Text> de alaU.</Text>
            </p>
          </Card.Body>
        </Card>
      </LoginWrapper>
    </Column>
  )
}

const LoginWrapper = styled.div`
  width: 420px;
  margin-top: ${spacing.large};

  @media ${mediaQueries.xsmallMax} {
    width: 100%;
  }
`

const MessageContainer = styled.div`
  margin-bottom: ${spacing.small};
`

const WrapperForm = styled.div`
  margin: 15px 0px;
`

export default SignupComponent
