export const urlCourse = course => {
  if (course === '5b50fce65abf1ce766866056') {
    return 'https://alau.org/ser-bachiller'
  } else if (course === '5b51006477ca6de86fe805d4') {
    return 'https://alau.org/icfes-saber-11'
  } else if (course === '5c3f78b43d498b00632e2410') {
    return 'https://alau.org/estudio-eficiente'
  } else {
    return 'https://alau.org'
  }
}

export const COUNTRY_ECUADOR = '5b50bd028b1b6bd742c5ac63'
