import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useAsync } from 'react-async'
import Heading from '../../components/Heading'
import Icon from '../../components/Icon'
import FormEF from './components/FormEF'
import DetailRequirements from './components/DetailRequirements'
import Loading from '../Loading'
import { useNotification } from '../../context/notificationContext'
import { colors, mediaQueries, spacing } from '../../tokens'
import {
  CourseRequirements,
  CourseDetailRequirements,
} from '../../lib/formatApiObjects'
import { getCourseRequirements } from '../../lib/api'

function Requirements() {
  const history = useHistory()
  const params: any = useParams()
  const { notify } = useNotification()
  const [
    detailRequirements,
    setDetailRequirements,
  ] = useState<CourseDetailRequirements | null>(null)
  const [courseId, setCourseId] = useState<string | null>(null)

  const onClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    redirectPage()
  }

  function redirectPage() {
    if (history.action === 'PUSH') {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  function onCompletedForm(): void {
    notify(
      'Listo, tus datos se han enviado exitosamente. Muchas gracias por tu tiempo.',
      'success',
    )
    redirectPage()
  }

  const {
    isLoading,
  }: {
    data: CourseRequirements | undefined
    isLoading: boolean
  } = useAsync({
    promiseFn: getCourseRequirements,
    slug: params.slug,
    onReject: error => notify(error.message, 'error'),
    onResolve: (data: CourseRequirements) => {
      if (data) {
        setDetailRequirements(data.detailRequirements)
        setCourseId(data._id)
      }
    },
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <Wrapper>
      {!detailRequirements ? (
        <Heading size={3}>Lo sentimos no existe información</Heading>
      ) : (
        <Content>
          <Column>
            <DetailRequirements
              image={detailRequirements.image}
              title={detailRequirements.title}
              description={detailRequirements.description}
            />
          </Column>
          {courseId && (
            <Column>
              <WrapperForm>
                <FormEF course={courseId} onCompleted={onCompletedForm} />
              </WrapperForm>
            </Column>
          )}
        </Content>
      )}
      <CloseButton onClick={onClose}>
        <Icon name="close" size="small" />
      </CloseButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${colors.base.white};
  display: flex;
  position: relative;
  min-height: 100%;
  width: 100%;
  @media ${mediaQueries.mediumMax} {
    height: auto;
  }
`

const CloseButton = styled.div`
  background: ${colors.base.white};
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  right: ${spacing.xsmall};
  top: ${spacing.xsmall};
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin: 0 auto;
  @media ${mediaQueries.mediumMin} {
    max-width: 100%;
  }

  @media ${mediaQueries.largeMin} {
    max-width: 992px;
  }

  @media ${mediaQueries.xlargeMin} {
    max-width: 1200px;
  }
`

const Column = styled.div`
  display: flex;
  flex: 50%;
  flex-direction: column;
  overflow-y: scroll;
  padding: ${spacing.small};
  @media ${mediaQueries.mediumMax} {
    flex: 100%;
  }
  @media ${mediaQueries.smallMin} {
    overflow-y: auto;
    height: auto;
  }
`

const WrapperForm = styled.div`
  @media ${mediaQueries.mediumMin} {
    margin: auto 0;
  }
`

export default Requirements
