import React from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import { colors, fonts, spacing } from '../../tokens'

interface Props {
  /** if select is searchable */
  isClearable?: boolean
  /** if select is searchable */
  isSearchable?: boolean
  /** Show if select contains a valid value */
  isValid?: boolean | null
  /** label to show */
  label?: string
  /** name of select */
  name: string
  /** Array of items to show in select */
  options: any
  /** value of each item */
  optionValue?: string
  /** label of each item */
  optionLabel?: string
  /** label to show */
  placeholder?: string
  /** Show * if select is required */
  required?: boolean
  /** value of item */
  value?: any
  /** Handler to be called when select change */
  onChange?: any
  /** The error for the input. */
  error?: string | null
}

const customStyle = {
  groupHeading: () => ({
    color: colors.base.grayLight,
    marginTop: 0,
    padding: spacing.small,
  }),
  group: (base: any) => ({
    ...base,
    margin: 0,
  }),
  menu: (base: any) => ({
    ...base,
    overflow: 'hidden',
  }),
  control: (base: any, state: any) => ({
    ...base,
    borderWidth: 1,
    borderColor: state.isFocused
      ? colors.base.purple
      : colors.base.grayLightest,
    '&:hover': { borderColor: colors.base.grayLight },
    boxShadow: 'none',
    fontSize: `${fonts.size.default}`,
    marginTop: spacing.xxsmall,
  }),
  option: (provided: any, state: any) => {
    let background = colors.base.white
    let color = colors.base.gray
    if (state.isSelected) {
      background = colors.base.purple
      color = colors.base.white
    }
    if (state.isFocused) {
      background = colors.base.grayLightest
      color = colors.base.gray
    }
    return {
      ...provided,
      color: color,
      padding: `${spacing.xsmall} ${spacing.small}`,
      fontSize: `${fonts.size.default}`,
      backgroundColor: background,
    }
  },
}

function Select({
  label = '',
  isSearchable = true,
  isClearable = true,
  name,
  options,
  optionValue = 'value',
  optionLabel = 'label',
  placeholder = '',
  required = false,
  isValid = null,
  value,
  onChange,
  error,
}: Props) {
  if (value !== null && value !== '') {
    isValid = true
  }
  return (
    <Wrapper>
      {label !== '' ? (
        <Label isValid={isValid}>
          {label}
          {required && '*'}
        </Label>
      ) : null}
      <ReactSelect
        value={value}
        name={name}
        onChange={onChange}
        options={options}
        theme={theme => ({
          ...theme,
          borderRadius: 8,
          colors: {
            ...theme.colors,
            primary50: `${colors.base.grayLightest}`, //color onpress
          },
        })}
        styles={customStyle}
        placeholder={placeholder}
        noOptionsMessage={() => '-'}
        getOptionValue={(option: any) => option[optionValue]}
        getOptionLabel={(option: any) => `${option[optionLabel]}`}
        isSearchable={isSearchable}
        isClearable={isClearable}
      />
      {error && <Error>{error}</Error>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: ${spacing.small};
`

const Label = styled.label<{ isValid: boolean | null }>`
  color: ${props =>
    props.isValid !== null && !props.isValid
      ? colors.base.red
      : colors.base.gray};
  font-size: ${fonts.size.small};
  font-family: ${fonts.family.default};
`

const Error = styled.div`
  color: ${colors.base.red};
  font-size: ${fonts.size.small};
  margin-top: ${spacing.xxsmall};
`

export default Select
