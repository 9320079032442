import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Login from './pages/Login'
import Signup from './pages/Signup'
import ResetPassword from './pages/ResetPassword'
import Curso from './pages/Courses'
import Dashboard from './pages/Course'
import Resultado from './components/paginas/Resultado'
import Quiz from './components/paginas/Quiz'
import withAnalyticsTracking from './pages/withAnalyticsTracking'
import NewPassword from './pages/NewPassword'
import PaymentSuccess from './pages/PaymentSuccess'
import PaymentFailed from './pages/PaymentFailed'
import NotFound from './components/NotFound'
import CreatePrivateRoute from './CreatePrivateRoute'
import Requirements from './pages/Requirements'
import Suscription from './pages/Suscription'
import TerminosYCondiciones from './pages/TerminosYCondiciones'
import Certificate from './pages/Certificate'
import VerifyCertificate from './pages/VerifyCertificate'
import MyCourses from './pages/MyCourses'
import Practice from './pages/Practice'

const LoginWithTracking = withAnalyticsTracking(Login)
const SignupWithTracking = withAnalyticsTracking(Signup)
const ResetPasswordWithTracking = withAnalyticsTracking(ResetPassword)
const NewPasswordWithTracking = withAnalyticsTracking(NewPassword)
const CursoWithTracking = withAnalyticsTracking(Curso)
const DashboardWithTracking = withAnalyticsTracking(Dashboard)
const PracticeWithTracking = withAnalyticsTracking(Practice)
const ResultadoWithTracking = withAnalyticsTracking(Resultado)
const QuizWithTracking = withAnalyticsTracking(Quiz)
const PaymentSuccessWithTracking = withAnalyticsTracking(PaymentSuccess)
const PaymentFailedWithTracking = withAnalyticsTracking(PaymentFailed)
const SuscriptionWithTracking = withAnalyticsTracking(Suscription)
const RequirementsWithTracking = withAnalyticsTracking(Requirements)
const TerminosYCondicionesWithTracking = withAnalyticsTracking(
  TerminosYCondiciones,
)
const CertificateWithTracking = withAnalyticsTracking(Certificate)
const VerifyCertificateWithTracking = withAnalyticsTracking(VerifyCertificate)
const MyCoursesWithTracking = withAnalyticsTracking(MyCourses)

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={CursoWithTracking} />
        <Route exact path="/iniciar-sesion" component={LoginWithTracking} />
        <Route exact path="/registro" component={SignupWithTracking} />
        <Route
          exact
          path="/reset_password"
          component={ResetPasswordWithTracking}
        />
        <Route
          exact
          path="/change_password/:token"
          component={NewPasswordWithTracking}
        />
        <Route
          exact
          path="/cursos/:slug/:content?"
          {...CreatePrivateRoute({ component: DashboardWithTracking })}
        />
        <Route
          exact
          path="/simulador/:slug/:content"
          {...CreatePrivateRoute({ component: PracticeWithTracking })}
        />
        <Route
          exact
          path="/resultado/:simulador"
          {...CreatePrivateRoute({ component: ResultadoWithTracking })}
        />
        <Route
          exact
          path="/quiz/:quiz"
          {...CreatePrivateRoute({ component: QuizWithTracking })}
        />
        <Route
          exact
          path="/payment_success"
          component={PaymentSuccessWithTracking}
        />
        <Route
          exact
          path="/payment_failed"
          component={PaymentFailedWithTracking}
        />
        <Route
          exact
          path="/suscripcion/:country?"
          component={SuscriptionWithTracking}
        />
        <Route
          exact
          path="/requerimientos/:slug"
          component={RequirementsWithTracking}
        />
        <Route
          exact
          path="/terminos-y-condiciones"
          component={TerminosYCondicionesWithTracking}
        />
        <Route
          exact
          path="/certificates/:key"
          component={CertificateWithTracking}
        />
        <Route
          exact
          path="/verificar-certificado/:courseSlug"
          component={VerifyCertificateWithTracking}
        />
        <Route
          exact
          path="/mis-cursos/"
          {...CreatePrivateRoute({ component: MyCoursesWithTracking })}
        />
        <Route component={NotFound} />
      </Switch>
    </>
  )
}

export default App
