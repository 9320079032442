import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAsync } from 'react-async'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'

import {
  getCountries,
  getCities,
  courseRequirementsOfUser,
} from '../../../lib/api'
import { useNotification } from '../../../context/notificationContext'
import Button from '../../../components/Button'
import Loading from '../../Loading'
import Select from '../../../components/Select'
import TextInput from '../../../components/TextInput'
import { colors, fonts, mediaQueries, spacing } from '../../../tokens'
import { useUser } from '../../../context/userContext'
import { requirementsSchema } from '../../../util/validations'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'

registerLocale('es', es)

interface Props {
  course: string
  onCompleted(): void
}

interface LocalizationType {
  _id: string
  nombre: string
}

interface OptionType {
  value: string
  label: string
}

interface User {
  documentoIdentificacion: string
  nombre: string
  email: string
  pais: LocalizationType | null
  ciudad: LocalizationType | null
  sexo: OptionType | null
  telefono: string
  fechaNacimiento: any
}

interface OtherLocalization {
  otherCountry: string
  otherCity: string
}

interface ErrorsType {
  [propName: string]: string
}

const otherCountry = [
  {
    _id: '0',
    nombre: 'Otro',
  },
]

const genres = [
  { value: 'femenino', label: 'Femenino' },
  { value: 'masculino', label: 'Masculino' },
  { value: 'otro', label: 'Otro' },
]

function FormEF({ course, onCompleted }: Props) {
  const { notify } = useNotification()
  const { user } = useUser()

  const [errors, setErrors] = useState<ErrorsType | null>(null)

  const [userInfo, setUserInfo] = useState<User>({
    documentoIdentificacion: '',
    nombre: user.nombre ? user.nombre : '',
    email: user.email ? user.email : '',
    pais: null,
    ciudad: null,
    sexo: null,
    telefono: '',
    fechaNacimiento: '',
  })

  const [cities, setCities] = useState<LocalizationType[]>([])

  const [otherLocalization, setOtherLocalization] = useState<OtherLocalization>(
    {
      otherCountry: '',
      otherCity: '',
    },
  )

  const dataOfCountries = useAsync({
    promiseFn: getCountries,
    onReject: error => notify(error.message, 'error'),
  })

  const dataOfCities = useAsync({
    deferFn: args => getCities(args[0]),
    onReject: error => notify(error.message, 'error'),
    onResolve: (data: any) => {
      reloadCities(data.cities)
    },
  })

  const reloadCities = (cities: LocalizationType[]) => {
    setCities([...cities])
  }

  useEffect(() => {
    if (userInfo.pais && userInfo.pais._id !== '0') {
      dataOfCities.run(userInfo.pais._id)
    } else {
      setCities([])
    }
  }, [userInfo.pais])

  function handleChangeInput(value: string, id: string) {
    setUserInfo({
      ...userInfo,
      [id]: value,
    })
  }

  function handleChangeBirthday(selectedData: Date) {
    setUserInfo({
      ...userInfo,
      fechaNacimiento: selectedData,
    })
  }

  function handleOtherLocalization(value: string, id: string) {
    setOtherLocalization({
      ...otherLocalization,
      [id]: value,
    })
  }

  function handleGenre(selectedOption: any) {
    setUserInfo({
      ...userInfo,
      sexo: selectedOption,
    })
  }

  function handleLocalization(selectedOption: LocalizationType, action: any) {
    if (action.name === 'pais') {
      setUserInfo({
        ...userInfo,
        ciudad: null,
        pais: selectedOption,
      })
    } else {
      setUserInfo({
        ...userInfo,
        ciudad: selectedOption,
      })
    }
  }

  function isOtherCountry() {
    return userInfo.pais && userInfo.pais._id === '0'
  }

  const sendRequeriments = useAsync({
    deferFn: args => courseRequirementsOfUser(args[0], course),
    onReject: error => notify(error.message, 'error'),
    onResolve: () => {
      onCompleted()
    },
  })

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    let localization = {}
    if (isOtherCountry()) {
      localization = {
        pais: { _id: 0, nombre: otherLocalization.otherCountry },
        ciudad: { _id: 0, nombre: otherLocalization.otherCity },
      }
    } else {
      localization = {
        pais: userInfo.pais
          ? { _id: userInfo.pais._id, nombre: userInfo.pais.nombre }
          : {},
        ciudad: userInfo.ciudad
          ? { _id: userInfo.ciudad._id, nombre: userInfo.ciudad.nombre }
          : {},
      }
    }

    const body = {
      ...userInfo,
      sexo: userInfo && userInfo.sexo ? userInfo.sexo.value : null,
      ...localization,
    }

    requirementsSchema
      .validate(body, { abortEarly: false })
      .then(function() {
        sendRequeriments.run(body)
      })
      .catch(function(err) {
        const validationErrors: ErrorsType = {}
        if (err.inner) {
          err.inner.forEach((error: any) => {
            if (error.path) {
              validationErrors[error.path] = error.message
            }
          })
        }
        setErrors(validationErrors)
      })
  }

  if (sendRequeriments.isLoading) {
    return <Loading />
  }

  return (
    <Form onSubmit={handleSubmit}>
      <TextInput
        label="Documento de identificación"
        name="documentoIdentificacion"
        onChange={handleChangeInput}
        value={userInfo.documentoIdentificacion}
        error={errors && errors.documentoIdentificacion}
      />
      <WrapperInputAndLabel>
        <Label>Fecha de nacimiento*</Label>
        <div>
          <DatePickerCustomInput
            dateFormat="yyyy/MM/dd"
            selected={userInfo.fechaNacimiento}
            onChange={handleChangeBirthday}
            minDate={new Date('1940-01-02')}
            maxDate={new Date('2009-01-01')}
            yearDropdownItemNumber={68}
            scrollableYearDropdown
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            locale="es"
            className="custom-datepicker"
          />
          {errors && errors.fechaNacimiento && (
            <Error>{errors.fechaNacimiento}</Error>
          )}
        </div>
      </WrapperInputAndLabel>
      <TextInput
        label="Nombre Completo"
        name="nombre"
        onChange={handleChangeInput}
        value={userInfo.nombre}
        error={errors && errors.nombre}
      />
      <TextInput
        label="Email"
        name="email"
        onChange={handleChangeInput}
        value={userInfo.email}
        readOnly={user.email ? true : false}
        error={errors && errors.email}
      />
      {dataOfCountries.data ? (
        <Select
          value={userInfo.pais as LocalizationType}
          label="País"
          name="pais"
          options={[...dataOfCountries.data.countries, ...otherCountry]}
          onChange={handleLocalization}
          optionValue={'_id'}
          optionLabel={'nombre'}
          error={errors && errors['pais._id']}
        />
      ) : null}
      {cities.length && !isOtherCountry() ? (
        <Select
          value={userInfo.ciudad as LocalizationType}
          label="Ciudad"
          name="ciudad"
          options={cities}
          onChange={handleLocalization}
          optionValue={'_id'}
          optionLabel={'nombre'}
          error={errors && errors['ciudad._id']}
        />
      ) : null}
      {isOtherCountry() ? (
        <WrapperOtherLocalization>
          <div>
            <TextInput
              label="Ingresa tu País"
              name="otherCountry"
              onChange={handleOtherLocalization}
              value={otherLocalization.otherCountry}
              error={errors && errors['pais.nombre']}
            />
          </div>
          <div>
            <TextInput
              label="Ingresa tu Ciudad"
              name="otherCity"
              onChange={handleOtherLocalization}
              value={otherLocalization.otherCity}
              error={errors && errors['ciudad.nombre']}
            />
          </div>
        </WrapperOtherLocalization>
      ) : null}
      <Select
        value={userInfo.sexo}
        label="Género"
        name="sexo"
        options={genres}
        onChange={handleGenre}
        isSearchable={false}
        error={errors && errors.sexo}
      />
      <TextInput
        label="Número de celular"
        name="telefono"
        onChange={handleChangeInput}
        type="text"
        value={userInfo.telefono}
        error={errors && errors.telefono}
      />
      <WrapperButton>
        <Button type="submit">Continuar</Button>
      </WrapperButton>
      {errors && (
        <Error style={{ textAlign: 'center' }}>
          El formulario contiene errores, por favor revisa que toda la
          información esté correcta
        </Error>
      )}
    </Form>
  )
}

const Form = styled.form`
  @media ${mediaQueries.mediumMin} {
    margin: 0 auto;
    width: 75%;
  }
`

const Label = styled.label`
  color: ${colors.base.gray};
  font-size: ${fonts.size.small};
  font-family: ${fonts.family.default};
`

const WrapperButton = styled.div`
  text-align: center;
`

const WrapperOtherLocalization = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 100%;
    &:first-child {
      margin-right: ${spacing.small};
    }
  }
  @media ${mediaQueries.smallMax} {
    flex-direction: column;
  }
`

const Error = styled.div`
  color: ${colors.base.red};
  font-size: ${fonts.size.small};
  margin: ${spacing.xxsmall} 0px ${spacing.small};
`

const DatePickerCustomInput = styled(DatePicker)`
  border: solid 1px ${colors.base.grayLightest};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${colors.base.gray};
  font-size: ${fonts.size.default};
  font-family: ${fonts.family.default};
  margin-top: ${spacing.xxsmall};
  padding: ${spacing.xsmall};
  transition: border-color 0.25s, box-shadow 0.25s;
  width: 100%;
  &:hover {
    border-color: ${colors.base.grayLight};
  }
  &:focus {
    border-color: ${colors.base.purple};
    box-shadow: 0px 0px 0px 1px ${colors.base.grayLightest};
    outline: none;
  }
  &::placeholder {
    font-size: ${fonts.size.small};
    font-family: ${fonts.family.default};
  }
`

const WrapperInputAndLabel = styled.div`
  margin-bottom: ${spacing.small};
  text-align: left;
`

export default FormEF
